import $ from 'jquery'

$('.js-nav').each( initMenu )
$(document).on( 'click', '.js-sidebar-toggle', function( e ) { toggleSidebar()})

$( initLayout )

addEventListener( "resize", initLayout )

function initMenu(){
  const $menu = $(this)
  const $expandable = $menu.find('li.page_item_has_children, .menu-item-has-children')
  const $buttons = $expandable.children('a')

  $buttons.on( 'click', function(e){
    e.preventDefault()
    
    const $button = $(this)
    const closeOthers = ()=>{
      $buttons.not( $button ).removeClass('active')
      $expandable
        .not( $button.parents() )
        .not( $('.current_page_ancestor') )
        .children( '.children, .sub-menu' )
        .slideUp()
    }

    closeOthers()
    if( ! $button.is( '.current_page_ancestor a') ){
      $button.toggleClass('active')
      $button.siblings('.children, .sub-menu').slideToggle()
    }
  })
}

function initLayout(){
  closeSidebar()
  // if( window.innerWidth < 901 ){
  //   $('.js-sidebar-toggle').addClass( 'sidebar__toggle--open')
  //   $('.js-sidebar').addClass( 'sidebar--closed' )
  //   $('.js-sidebar-hide').addClass( 'show' )
  // } else {
  //   $('.js-sidebar-toggle').removeClass( 'sidebar__toggle--open')
  //   $('.js-sidebar').removeClass( 'sidebar--closed' )
  //   $('.js-sidebar-hide').removeClass( 'show' )
  // }
}

function closeSidebar(){
  const bodyClasses = document.querySelector('body').classList
  bodyClasses.remove( 'layout--sidebar-open' )
  bodyClasses.add( 'layout--sidebar-closed' )
}
function toggleSidebar(){
  const bodyClasses = document.querySelector('body').classList
  bodyClasses.toggle( 'layout--sidebar-closed' )
  bodyClasses.toggle( 'layout--sidebar-open' )
  // $('.js-sidebar').toggleClass( 'sidebar--closed' )
  // $('.js-sidebar-hide').toggleClass( 'show' )
}
